import React from "react";
import { useState } from "react";
import "./qualification.css";

const Qualification = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };


  return (
    <section id="qualification" className="qualification section">
      <h2 className="section__title">Qualification</h2>
      <span className="section__subtitle">My personal journey </span>

      <div className="qualification__container container">
        <div className="qualification__tabs">
          <div
            onClick={() => toggleTab(1)}
            className={
              toggleState === 1
                ? "qualification__button qualification__active button--flex"
                : "qualification__button  button--flex"
            }
          >
            <i className="uli uil-graduation-cap qualification__icon"></i>
            {""}Education
          </div>

          <div
            onClick={() => toggleTab(2)}
            className={
              toggleState === 2
                ? "qualification__button qualification__active button--flex"
                : "qualification__button  button--flex"
            }
          >
            <i className="uli uil-briefcase-alt qualification__icon"></i>
            {""}Experience
          </div>
        </div>

        <div className="qualification__sections">
          {/* 4 elem */}
          <div
            className={
              toggleState === 1
                ? "qualification__content qualification__content-active"
                : "qualification__content "
            }
          >
            <div className="qualification__data">
              <div >
                <h3 className="qualification__title">Web master</h3>
                <a href="https://academy.codershiyar.com/certification.php?certificate_number=CSC-20210615-4130" className="qualification__subtitle">
                  Shiyar Academy
                </a>
                <div className="qualification__calendar">
                  <i className="uli uil-calendar-alt icon_cal"></i>
                  2020
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>

              <div>
                <h3 className="qualification__title">Python master</h3>
                <a href="https://www.youtube.com/watch?v=mvZHDpCHphk&list=PLDoPjvoNmBAyE_gei5d18qkfIe-Z8mocs&pp=iAQB" className="qualification__subtitle">
                Elzero Web School
                </a>
                <div className="qualification__calendar">
                  <i className="uli uil-calendar-alt icon_cal"></i>
                  2021
                </div>
              </div>
            </div>

            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Django & APIs</h3>
                <a href="https://www.youtube.com/watch?v=kiZPBsYqcPo&list=PLMYF6NkLrdN_5wAJRbEoQYl62-qM3aB8t" className="qualification__subtitle">
                    Hassouna Academy
                </a>
                <div className="qualification__calendar">
                  <i className="uli uil-calendar-alt icon_cal"></i>
                  2021
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

                <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>

              <div>
                <h3 className="qualification__title">Bachelor In CS</h3>
                <a href="https://www.youtube.com/watch?v=LgB3j2y1b-4&list=PLtFbQRDJ11kEjXWZmwkOV-vfXmrEEsuEW" className="qualification__subtitle">
                    Jadara University
                </a>
                <div className="qualification__calendar">
                  <i className="uli uil-calendar-alt icon_cal"></i>
                2023 - present

                </div>
              </div>
                  
            </div>
            </div>


          <div
            className={
              toggleState === 2
                ? "qualification__content qualification__content-active"
                : "qualification__content "
            }
          >
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Freelancer</h3>
                <span  className="qualification__subtitle">
                  Fiver / Upwork
                </span>
                <div className="qualification__calendar">
                  <i className="uli uil-calendar-alt icon_cal"></i>
                  2021 - present
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>

              <div>
                <h3 className="qualification__title"> Microcontroller Workshop</h3>
                <a href="https://www.facebook.com/profile.php?id=100069921446833" className="qualification__subtitle">
                Social Innovation Incubator - UNICEF
                </a>
                <div className="qualification__calendar">
                  <i className="uli uil-calendar-alt icon_cal"></i>
                  2021 - present
                </div>
              </div>
            </div>

            {/* <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Web Des</h3>
                <span className="qualification__subtitle">
                  Figma - Institute
                </span>
                <div className="qualification__calendar">
                  <i className="uli uil-calendar-alt icon_cal"></i>
                  2015 - present
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Qualification;
