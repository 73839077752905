import React from "react";



const Social = () => {
    return (
        <div className="home__social">
            <a href="https://www.instagram.com/ai_alrefa3ee/" className="home__social-icon">
                
            <i class="fa-brands fa-instagram"></i>
            </a>

            <a href="https://www.linkedin.com/in/abdulmajed-al-rifai-70820428a/"  className="home__social-icon">
            <i class="fa-brands fa-linkedin"></i>
            </a>

            <a href="https://github.com/Alrefa3ee"  className="home__social-icon">
            <i class="fa-brands fa-github"></i>
            </a>
            
        
        </div>
            
   )};


export default Social;
