import React from "react";



const Info = () => {
    return (
        <div className="about__info grid">

            <div className="about__box">
                    <i class='bx bx-award about__icon'></i>
                    <h3 className="about__title">Experience</h3>
                    <span className="about__subtitle">2 Years Working</span>
     
            </div>

            <div className="about__box">
                    <i class='bx bx-briefcase about__icon' ></i>
                    <h3 className="about__title">Complated</h3>
                    <span className="about__subtitle">+15 Projects</span>

            </div>
                    
            <div className="about__box">
                    <i class='bx bx-support about__icon' ></i>
                    <h3 className="about__title">Avaliable</h3>
                    <span className="about__subtitle">24/7</span>

            </div>
        </div>
    )
}

export default Info;    