import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container container">
        <h1 className="footer__title">Abdalmajeed</h1>
        <ul className="footer__list">
        <li>
            <a href="#home" className="footer__link">
              Home
            </a>
          </li>
          <li>
            <a href="#about" className="footer__link">
              About
            </a>
          </li>
          <li>
            <a href="#qualification" className="footer__link">
            Qualification
            </a>
          </li>

        </ul>

        <div className="footer__social">
          <a href="https://www.instagram.com/ai_alrefa3ee/" className="footer__social-icon">
            <i class="fa-brands fa-instagram"></i>
          </a>

          <a href="https://www.linkedin.com/in/abdulmajed-al-rifai-70820428a/"   className="footer__social-icon">
            <i class="fa-solid fa-linkedin"></i>
          </a>

          <a href="https://github.com/Alrefa3ee" className="footer__social-icon">
            <i class="fa-brands fa-github"></i>
          </a>
        </div>
        <span className="footer__copy">
            &#169; Crypticalcoder. All rights reserved.
        </span>
      </div>
    </footer>
  );
};


export default Footer;
