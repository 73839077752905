import React from "react";
import { useState } from "react";
import "./services.css";

const Services = () => {

    const [ toggleState, setToggleState ] = useState(0);

    const toggleTab = (index) => {
        setToggleState(index);
    }


    return(
        <section className="services section" id="services">
            <h2 className="section__title">Services</h2>
            <span className="section__subtitle">What I offer</span>


            <div className="services__container container grid">
                <div className="services__contect">
                    <div>
                        <i className="uil uil-web-grid services__icon"></i>
                        <h3 className="services__title">Prodect <br/> Designer</h3>
                    </div>

                    <span className="services__button" onClick={() => toggleTab(1)}>
                        View More
                        <i className="uil uil-arrow-right services__button-icon"></i>
                    </span>

                    <div className={toggleState === 1 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                           
                            <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
                            <h3 className="services__modal-title">Prodect Designer</h3>
                                    <p className="services__modal-description">
                                        services with more 2 years of experience designing and developing
                                        websites, I can help you with your web project.

                                        aloso I have experience in the following areas: web design,
                                        front-end developer, wordpress, back-end developer, AI Styles platform.

                                    </p>

                                    <ul className="services__modal-services grid">

                                        <li className="services__modal-service">
                                            <i className="uil uil-check-circle services__modal-icon"></i>
                                            <p className="services__modal-info">Web design</p>
                                        </li>

                                        <li className="services__modal-service">
                                            <i className="uil uil-check-circle services__modal-icon"></i>
                                            <p className="services__modal-info">Front-end developer</p>
                                        </li>

                                        <li className="services__modal-service">
                                            <i className="uil uil-check-circle services__modal-icon"></i>
                                            <p className="services__modal-info">AI Styles platform</p>
                                        </li>

                                        <li className="services__modal-service">
                                            <i className="uil uil-check-circle services__modal-icon"></i>
                                            <p className="services__modal-info">Wordpress</p>
                                        </li>



                                    </ul>

                        </div>
                    </div>
                </div>



                <div className="services__contect">
                    <div>
                    <i className="uil uil-edit services__icon"></i>
                        <h3 className="services__title">Systems<br/>  Architect </h3>
                    </div>

                    <span className="services__button" onClick={() => toggleTab(2)}>
                        View More 
                        <i className="uil uil-arrow-right services__button-icon"></i>
                    </span>

                    <div className={toggleState === 2 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                           
                            <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
                            <h3 className="services__modal-title"> Architect </h3>
                                    <p className="services__modal-description">
                                        serve in the area of ​​systems 
                                        experience designing and developing the architecture

                                        aloso I have experience in the following areas: Web apps Manager,
                                        Web Structure, AI generators, Clean Code.

                                    </p>

                                    <ul className="services__modal-services grid">

                                        <li className="services__modal-service">
                                            <i className="uil uil-check-circle services__modal-icon"></i>
                                            <p className="services__modal-info">Web apps</p>
                                        </li>

                                        <li className="services__modal-service">
                                            <i className="uil uil-check-circle services__modal-icon"></i>
                                            <p className="services__modal-info">Web Structure</p>
                                        </li>

                                        <li className="services__modal-service">
                                            <i className="uil uil-check-circle services__modal-icon"></i>
                                            <p className="services__modal-info">AI generators</p>
                                        </li>
                                        <li className="services__modal-service">
                                            <i className="uil uil-check-circle services__modal-icon"></i>
                                            <p className="services__modal-info">Clean Code</p>
                                        </li>

                                    </ul>

                        </div>
                    </div>
                </div>



                <div className="services__contect" >
                    <div>
                        
                        <i className="uil uil-arrow services__icon"></i>
                        <h3 className="services__title">Prodect <br/> Manager</h3>
                    </div>

                    <span className="services__button" onClick={()=>toggleTab(3)}>
                        View More 
                        <i className="uil uil-arrow-right services__button-icon"></i>
                    </span>

                    <div className={toggleState === 3 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                           
                            <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
                            <h3 className="services__modal-title">Prodect Manager</h3>
                                    <p className="services__modal-description">
                                        serve in   developing and deploying
                                        websites, I can help you with your web project.

                                        aloso I have experience in the following areas: web development,
                                        back-end developer , APIs and Integrations, Database Management.

                                    </p>

                                    <ul className="services__modal-services grid">

                                        <li className="services__modal-service">
                                            <i className="uil uil-check-circle services__modal-icon"></i>
                                            <p className="services__modal-info">Back-end Developer</p>
                                        </li>

                                        <li className="services__modal-service">
                                            <i className="uil uil-check-circle services__modal-icon"></i>
                                            <p className="services__modal-info">APIs and Integrations</p>
                                        </li>

                                        <li className="services__modal-service">
                                            <i className="uil uil-check-circle services__modal-icon"></i>
                                            <p className="services__modal-info">Building & Deploying </p>
                                        </li>

                                        <li className="services__modal-service">
                                            <i className="uil uil-check-circle services__modal-icon"></i>
                                            <p className="services__modal-info">Database Management</p>
                                        </li>



                                    </ul>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default Services;
