import React ,{useEffect, useState} from "react";
import { projectsData, projecstNav } from "./Data";
import WorkItems from "./WorkItems";

const Works = () => {
    const [item, setItem] = useState({name:"all"});
    const [projects, setProjects] = useState([]);
    const [active, setActive] = useState(0);



    useEffect(() => {

        if(item.name === 'all'){
            setProjects(projectsData);
        }
        else {
            const newProjects = projectsData.filter((project) => project.category === item.name);
            setProjects(newProjects);
        }
    },[item])

    const handleClick = (e, index) => {
        setItem({name:e.target.textContent});
        setActive(index);
    }
    return (
        <div>              
                <div className="work__filters">
                    {projecstNav.map((nav, i) => {
                        return (
                            <span onClick={(e)=>{handleClick(e,i)}} key={i} className={`${active ===i ? "active-work" : ""} work__item`}>
                                {nav.name}
                            </span>
                        );
                    })} 

                </div>  

                <div className="work__container container grid">
                    {projects.map((item) => {
                        return <WorkItems item={item} key={item.id}/>})}
                </div>
                        
                    
        </div>                  
    )               
}                   


export default Works;