import React from "react";
import "./scrollup.css";

const ScrollUp = () => {
  // disable scroll up button and activate it when scroll down 500px in Y axis
  window.onscroll = function () {
    scrollFunction();
  };

  const scrollFunction = () => {
    if (document.body.scrollTop > 600 || document.documentElement.scrollTop > 600) {
      document.querySelector(".scrollup").style.display = "block";
    } else {
      document.querySelector(".scrollup").style.display = "none";
    }
  };

  return (
    <a href="home" className="scrollup">

      <i className="uil uil-arrow-up scrollup__icon"></i>

    </a>
  );
};  


export default ScrollUp;