// import images
import Work1 from '../../assets/logo.735da2251127c1196763[1].jpg';
import Work3 from '../../assets/work3.jpg';
import Work4 from '../../assets/work4.jpg';
import Work5 from '../../assets/work5.jpg';
import Work6 from '../../assets/h.jpg';
import Work7 from '../../assets/work7.jpg';
export const projectsData = [
    {
        id:1,
        image:Work1,
        title:'Web Design',
        link:"https://aax.netlify.app/",
        category:'frontend'
    },
        {
        id:2,
        image:Work7,
        title:'AI E-Commerce',
        link:"https://github.com/Alrefa3ee/DR.Store",
        category:'backend'
    },

    {
        id:3,
        image:Work3,
        title:'Telegram Bot',
        link:"https://github.com/Alrefa3ee/TubePyBot",
        category:'backend'

    },
    {
        id:4,
        image:Work6,
        title:'Random Quotes',
        link:"https://random-quote-virid.vercel.app/",
        category:'backend'

    },
    {
        id:5,
        image:Work1,
        title:'Web Application',
        link:"https://link.com",
        category:'frontend'

    },

    {
        id:5,
        image:Work4,
        title:'robotics programming',
        link:"https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0oSKT6iXDqrYP1ZWaXErM5DBj2TuwArFcd9MKgE9uiQaXaUnZ3dgFB15oyNKwc6QZl%26id%3D108518693928865&show_text=true&width=500",
        category:'AI & IOT'

    },
    {
        id:5,
        image:Work5,
        title:'Face Mask Detection',
        link:"https://github.com/Alrefa3ee/Face_Mask_Detection-",
        category:'AI & IOT'

    },

];


export const projecstNav = [
    {
        name:'all',
    }
    ,
    {
        name:'frontend',
    }
    ,
    {
        name:'backend',
    }
    ,
    {
        name:'AI & IOT',
    } 
]
